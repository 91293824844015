
const Critical = ({ pos }) => {
  return (
    <svg width="100%" height="100%" viewBox="0 0 267 113" version="1.1" xmlns="http://www.w3.org/2000/svg"
      fill="currentColor"
      style={{
        "fillRule": "evenodd",
        "clipRule": "evenodd",
        opacity: pos
        //"strokeLineJoin": "round",
        //"strokeMiterLimit": 2
      }}
    >
      < g transform="matrix(1,0,0,1,-57.9785,-28.555)" >
        <g id="Layer-1">
        </g>
        <g transform="matrix(1,0,0,1,91.4732,-165.588)">
          <g id="text826">
            <path id="path11" d="M33.772,252.233C30.268,252.233 27.34,251.393 24.988,249.713C22.636,248.033 20.884,245.849 19.732,243.161C18.628,240.473 18.076,237.569 18.076,234.449C18.076,228.257 19.588,222.041 22.612,215.801C25.684,209.513 29.596,204.353 34.348,200.321C39.1,196.241 43.876,194.201 48.676,194.201C50.932,194.201 52.732,194.729 54.076,195.785C55.468,196.841 56.164,198.497 56.164,200.753C56.164,202.673 55.708,204.881 54.796,207.377C53.932,209.873 52.804,212.009 51.412,213.785C50.068,215.513 48.7,216.377 47.308,216.377C46.108,216.377 45.124,215.633 44.356,214.145C46.18,212.129 47.932,209.729 49.612,206.945C51.34,204.161 52.204,201.905 52.204,200.177C52.204,198.305 51.124,197.369 48.964,197.369C45.604,197.369 42.028,199.409 38.236,203.489C34.492,207.569 31.348,212.585 28.804,218.537C26.308,224.489 25.06,230.009 25.06,235.097C25.06,238.937 25.924,242.177 27.652,244.817C29.38,247.457 31.996,248.777 35.5,248.777C37.996,248.777 40.54,248.057 43.132,246.617C45.724,245.225 48.1,243.353 50.26,241.001C52.42,238.649 54.219,236.139 55.515,233.403L57.237,234.52C54.591,239.835 51.172,244.001 47.092,247.265C43.06,250.577 38.62,252.233 33.772,252.233Z"
              style={{ "fillRule": "nonzero" }}
            />


          </g>
        </g>
        <g transform="matrix(1,0,0,1,129.516,-165.574)">
          <g id="text8261" >
            <path id="path111" d="M29.092,247.841C27.316,247.841 25.876,247.361 24.772,246.401C23.668,245.489 23.116,244.241 23.116,242.657C23.116,241.745 23.284,240.761 23.62,239.705C24.004,238.601 24.556,237.281 25.276,235.745C25.9,234.401 26.38,233.273 26.716,232.361C27.052,231.401 27.22,230.561 27.22,229.841C27.22,228.017 26.02,226.481 23.62,225.233C22.66,227.633 21.532,230.057 20.236,232.505C18.988,234.953 17.86,237.017 16.852,238.697L15.772,236.609C17.98,232.673 19.924,228.329 21.604,223.577C21.124,223.097 20.884,222.449 20.884,221.633C20.884,220.385 21.268,219.209 22.036,218.105C22.804,217.001 23.548,216.449 24.268,216.449C24.748,216.449 25.132,216.737 25.42,217.313C25.756,217.889 25.924,218.369 25.924,218.753C25.924,218.945 25.804,219.377 25.564,220.049C25.372,220.673 25.228,221.129 25.132,221.417C25.18,221.753 25.372,222.089 25.708,222.425C26.044,222.713 26.668,223.145 27.58,223.721C29.26,224.825 30.556,225.905 31.468,226.961C32.428,227.969 32.908,229.337 32.908,231.065C32.908,232.121 32.716,233.177 32.332,234.233C31.996,235.289 31.468,236.561 30.748,238.049C29.692,240.161 29.164,241.673 29.164,242.585C29.164,243.689 29.764,244.241 30.964,244.241C32.404,244.241 33.964,243.665 35.644,242.513C37.372,241.361 38.932,239.969 40.324,238.337C41.764,236.657 42.82,235.097 43.492,233.657L45.22,234.953C44.26,237.113 42.916,239.177 41.188,241.145C39.46,243.113 37.516,244.721 35.356,245.969C33.244,247.217 31.156,247.841 29.092,247.841Z"
              style={{ "fillRule": "nonzero" }}
            />
          </g>
        </g>
        <g transform="matrix(1,0,0,1,155.706,-165.574)">
          <g id="text8262">
            <path id="path112" d="M30.028,215.657C29.02,215.657 28.156,215.417 27.436,214.937C26.764,214.457 26.428,213.761 26.428,212.849C26.428,211.745 27.028,210.785 28.228,209.969C29.476,209.105 30.796,208.673 32.188,208.673C34.252,208.673 35.284,209.561 35.284,211.337C35.284,212.345 34.732,213.329 33.628,214.289C32.524,215.201 31.324,215.657 30.028,215.657ZM21.532,248.129C19.516,248.129 17.956,247.529 16.852,246.329C15.748,245.129 15.196,243.593 15.196,241.721C15.196,239.801 15.724,237.209 16.78,233.945C17.884,230.633 19.252,227.729 20.884,225.233C22.564,222.737 24.244,221.489 25.924,221.489C26.548,221.489 27.052,221.681 27.436,222.065C27.868,222.401 28.084,222.905 28.084,223.577C28.084,224.009 27.892,224.609 27.508,225.377C27.124,226.097 26.5,227.153 25.636,228.545C24.1,231.089 22.876,233.345 21.964,235.313C21.1,237.233 20.668,239.057 20.668,240.785C20.668,242.273 20.932,243.281 21.46,243.809C21.988,244.337 22.804,244.601 23.908,244.601C27.7,244.601 31.468,240.929 35.212,233.585L36.292,234.737C34.612,238.961 32.476,242.249 29.884,244.601C27.292,246.953 24.508,248.129 21.532,248.129Z"
              style={{ "fillRule": "nonzero" }}
            />
          </g>
        </g>
        <g transform="matrix(1,0,0,1,173.466,-165.574)">
          <g id="text8263" >
            <path id="path113" d="M22.324,249.281C20.116,249.281 18.388,248.633 17.14,247.337C15.892,246.089 15.268,244.217 15.268,241.721C15.268,239.849 15.628,237.473 16.348,234.593C17.068,231.665 18.028,228.569 19.228,225.305C17.98,225.065 17.14,224.849 16.708,224.657L16.708,222.425C17.86,222.569 19.012,222.689 20.164,222.785C22.324,217.361 24.628,212.705 27.076,208.817C29.572,204.929 31.588,202.985 33.124,202.985C33.652,202.985 34.108,203.177 34.492,203.561C34.924,203.945 35.14,204.449 35.14,205.073C35.14,205.841 34.804,206.873 34.132,208.169C33.46,209.465 32.356,211.433 30.82,214.073C28.708,217.625 27.052,220.601 25.852,223.001C31.372,223.001 35.764,222.833 39.028,222.497L39.028,224.873C33.364,225.449 28.54,225.737 24.556,225.737C22.06,231.641 20.812,236.465 20.812,240.209C20.812,242.177 21.124,243.569 21.748,244.385C22.372,245.201 23.356,245.609 24.7,245.609C27.1,245.609 29.548,244.529 32.044,242.369C34.588,240.161 36.796,237.185 38.668,233.441L39.676,234.161C37.708,238.625 35.164,242.273 32.044,245.105C28.924,247.889 25.684,249.281 22.324,249.281Z"
              style={{ "fillRule": "nonzero" }}
            />
          </g>
        </g>
        <g transform="matrix(1,0,0,1,194.256,-165.574)">
          <g id="text8264" >
            <path id="path114" d="M30.028,215.657C29.02,215.657 28.156,215.417 27.436,214.937C26.764,214.457 26.428,213.761 26.428,212.849C26.428,211.745 27.028,210.785 28.228,209.969C29.476,209.105 30.796,208.673 32.188,208.673C34.252,208.673 35.284,209.561 35.284,211.337C35.284,212.345 34.732,213.329 33.628,214.289C32.524,215.201 31.324,215.657 30.028,215.657ZM21.532,248.129C19.516,248.129 17.956,247.529 16.852,246.329C15.748,245.129 15.196,243.593 15.196,241.721C15.196,239.801 15.724,237.209 16.78,233.945C17.884,230.633 19.252,227.729 20.884,225.233C22.564,222.737 24.244,221.489 25.924,221.489C26.548,221.489 27.052,221.681 27.436,222.065C27.868,222.401 28.084,222.905 28.084,223.577C28.084,224.009 27.892,224.609 27.508,225.377C27.124,226.097 26.5,227.153 25.636,228.545C24.1,231.089 22.876,233.345 21.964,235.313C21.1,237.233 20.668,239.057 20.668,240.785C20.668,242.273 20.932,243.281 21.46,243.809C21.988,244.337 22.804,244.601 23.908,244.601C27.7,244.601 31.468,240.929 35.212,233.585L36.292,234.737C34.612,238.961 32.476,242.249 29.884,244.601C27.292,246.953 24.508,248.129 21.532,248.129Z"
              style={{ "fillRule": "nonzero" }}
            />
          </g>
        </g>
        <g transform="matrix(1,0,0,1,212.016,-165.574)">
          <g id="text8265" >
            <path id="path115" d="M23.908,250.001C21.316,250.001 19.228,249.185 17.644,247.553C16.108,245.969 15.34,243.617 15.34,240.497C15.34,237.425 16.108,234.425 17.644,231.497C19.228,228.569 21.268,226.193 23.764,224.369C26.26,222.545 28.78,221.633 31.324,221.633C33.244,221.633 34.66,222.113 35.572,223.073C36.532,224.033 37.012,225.233 37.012,226.673C37.012,228.113 36.652,229.457 35.932,230.705C35.26,231.905 34.42,232.505 33.412,232.505C32.5,232.505 31.756,232.049 31.18,231.137C31.756,230.753 32.284,230.081 32.764,229.121C33.244,228.161 33.484,227.033 33.484,225.737C33.484,224.921 33.028,224.513 32.116,224.513C30.58,224.513 28.924,225.425 27.148,227.249C25.372,229.073 23.884,231.281 22.684,233.873C21.484,236.465 20.884,238.817 20.884,240.929C20.884,242.465 21.268,243.761 22.036,244.817C22.804,245.825 24.124,246.329 25.996,246.329C27.82,246.329 29.716,245.801 31.684,244.745C33.7,243.689 35.572,242.201 37.3,240.281C39.028,238.313 40.396,236.057 41.404,233.513L42.844,234.809C41.692,237.689 40.084,240.281 38.02,242.585C36.004,244.889 33.748,246.689 31.252,247.985C28.756,249.329 26.308,250.001 23.908,250.001Z"
              style={{ "fillRule": "nonzero" }}
            />
          </g>
        </g>
        <g transform="matrix(1,0,0,1,236.206,-165.574)">
          <g id="text8266" >
            <path id="path116" d="M19.804,249.353C18.124,249.353 16.732,248.801 15.628,247.697C14.476,246.593 13.9,245.033 13.9,243.017C13.9,240.233 14.908,237.113 16.924,233.657C18.988,230.201 21.604,227.273 24.772,224.873C27.94,222.473 31.108,221.273 34.276,221.273C36.388,221.273 38.14,221.825 39.532,222.929C40.972,223.985 41.692,225.353 41.692,227.033C41.692,229.241 40.324,230.393 37.588,230.489C39.316,231.017 40.18,231.737 40.18,232.649C40.18,232.985 40.012,233.489 39.676,234.161C39.34,234.833 38.956,235.529 38.524,236.249C37.756,237.497 37.156,238.601 36.724,239.561C36.292,240.473 36.076,241.337 36.076,242.153C36.076,242.873 36.244,243.497 36.58,244.025C36.964,244.505 37.516,244.745 38.236,244.745C39.58,244.745 41.284,243.809 43.348,241.937C45.46,240.065 47.5,237.257 49.468,233.513L50.836,234.881C49.06,238.865 46.852,242.081 44.212,244.529C41.572,246.929 38.908,248.129 36.22,248.129C34.3,248.129 32.812,247.481 31.756,246.185C30.748,244.937 30.244,243.497 30.244,241.865C28.084,244.457 26.236,246.353 24.7,247.553C23.164,248.753 21.532,249.353 19.804,249.353ZM21.316,245.681C22.996,245.681 24.94,244.481 27.148,242.081C29.404,239.633 31.948,236.465 34.78,232.577L36.22,230.633C36.364,230.393 36.556,230.273 36.796,230.273L37.084,230.345C37.324,229.577 37.444,228.689 37.444,227.681C37.444,225.185 36.436,223.937 34.42,223.937C32.308,223.937 30.1,225.065 27.796,227.321C25.492,229.577 23.548,232.265 21.964,235.385C20.428,238.505 19.66,241.169 19.66,243.377C19.66,244.193 19.78,244.793 20.02,245.177C20.308,245.513 20.74,245.681 21.316,245.681Z"
              style={{ "fillRule": "nonzero" }}
            />
          </g>
        </g>
        <g transform="matrix(1,0,0,1,267.956,-165.574)">
          <g id="text8267" >
            <path id="path117" d="M21.964,248.129C19.852,248.129 18.316,247.433 17.356,246.041C16.396,244.649 15.916,242.801 15.916,240.497C15.916,236.081 17.404,230.177 20.38,222.785C23.404,215.393 26.788,208.769 30.532,202.913C34.276,197.057 37.036,194.129 38.812,194.129C39.628,194.129 40.324,194.777 40.9,196.073C41.476,197.321 41.788,198.593 41.836,199.889C41.836,202.097 40.948,205.337 39.172,209.609C37.396,213.833 34.996,218.129 31.972,222.497C28.996,226.865 25.804,230.393 22.396,233.081C21.868,236.057 21.604,238.313 21.604,239.849C21.604,241.529 21.868,242.777 22.396,243.593C22.972,244.409 23.74,244.817 24.7,244.817C26.38,244.817 28.156,243.713 30.028,241.505C31.948,239.249 33.652,236.537 35.14,233.369L36.868,234.305C34.756,238.673 32.428,242.081 29.884,244.529C27.34,246.929 24.7,248.129 21.964,248.129ZM23.692,228.617C26.284,225.833 28.708,222.569 30.964,218.825C33.268,215.081 35.092,211.529 36.436,208.169C37.828,204.809 38.524,202.313 38.524,200.681C37.9,200.681 36.628,202.145 34.708,205.073C32.836,208.001 30.844,211.649 28.732,216.017C26.668,220.337 24.988,224.537 23.692,228.617Z"
              style={{ "fillRule": "nonzero" }}
            />
          </g>
        </g>
        <g transform="matrix(1,0,0,1,285.346,-165.574)">
          <g id="Layer-11" >
            <path id="path23-9" d="M27.453,224.625L27.453,224.629C22.916,224.64 18.277,227.962 16.268,235.678C16.263,235.697 15.725,238.061 15.707,238.447C15.69,238.824 15.714,238.847 15.945,239.049C16.119,239.123 16.337,239.18 16.535,238.933C17.336,237.922 18.417,237.027 18.879,235.867C20.262,233.521 20.485,233.319 21.465,231.459C27.428,222.344 34.905,231.673 31.994,239.48C29.741,243.819 21.166,249.688 11.141,252.91C0.759,256.645 -4.487,257.056 -14.425,257.29C-97.365,259.242 -91.573,246.001 -227.368,268.143C-215.1,273.655 -209.944,275.3 -205.948,282.999C-203.407,287.894 -201.375,291.786 -208.28,306.149C-130.607,275.949 -96.182,280.284 -55.771,277.157C-34.323,276.438 2.181,271.275 23.609,257.221C31.982,252.216 39.85,244.098 38.514,235.744C37.608,228.845 32.595,224.611 27.453,224.625Z"
              style={{ "fillRule": "nonzero" }}
            />
          </g>
        </g>
      </g >
    </svg >
  )
}

export default Critical
