import React, { FC, useEffect, useState, useRef } from 'react'
import Link from 'next/link'

const links = [
  { href: '/', label: 'projects', key: 'nav-index' },
  { href: '/about', label: 'about', key: 'nav-about' }
]

type NavProps = {
  toggle: (e: React.MouseEvent) => void
}
type NoNavProps = NavProps & {
  canPlayAudio: boolean
}

const FullNav: FC<NavProps> = ({
  toggle
}) => (
  <ul id="navigation" className="w-full top-0 right-0 flex bg-slate-900 text-slate-200">
    {links.map(({ key, href, label }) => (
      <li key={key} className="flex-none block-inline p-4 uppercase select-none">
        <Link href={href}>{label}</Link>
      </li>
    ))}
    <li className="flex-grow p-4 uppercase relative"><div className="absolute right-0 top-0 p-4 font-bold cursor-pointer select-none" onClick={toggle}>X</div></li>
  </ul>
)
const X: FC<{ canPlayAudio: boolean }> = ({
  canPlayAudio
}) => canPlayAudio
    ? <span className="cursor-pointer select-none">&#9776;</span>
    : <span className="cursor-pointer select-none">click me</span>

const NoNav: FC<NoNavProps> = ({
  toggle,
  canPlayAudio
}) => {
  const divRef = useRef(null)
  const [state, setState] = useState({
    top: '150px',
    right: '50%'
  })
  useEffect(() => {
    const resize = () => {
      const w = window.innerWidth
      const h = window.innerHeight
      const d = divRef.current.getBoundingClientRect()
      const right = Math.floor(w / 2 - d.width / 2) + 'px'
      const top = Math.floor(h / 2 - d.height / 2) + 'px'
      setState({ top, right })
    }
    resize()
    window.addEventListener('resize', resize)
    return () => window.removeEventListener('resize', resize)
  }, [])
  return (
    <ul id="navigation" className="w-full top-0 right-0 flex text-slate-100">
      <li className="flex-grow p-4 uppercase relative">
        <div
          ref={divRef}
          className="absolute p-4 font-bold bg-slate-900 cursor-pointer"
          style={{
            top: (canPlayAudio ? 0 : state.top),
            right: (canPlayAudio ? 0 : state.right),
            transition: 'all 1s ease'
          }}
          onClick={toggle}
        >
          <X canPlayAudio={canPlayAudio} />
        </div>
      </li>
    </ul>
  )
}

const Nav: FC<NoNavProps & { show: boolean }> = ({
  toggle,
  show,
  canPlayAudio
}) => show
    ? <FullNav toggle={toggle} />
    : <NoNav toggle={toggle} canPlayAudio={canPlayAudio} />

export default Nav
