import { useRef, useEffect, useState, useCallback } from 'react'

type AudioState = 'suspended' | 'running' | 'closed'
type zzz = {
  setVol: (vol: number) => void,
  resume: () => Promise<AudioState>
  audioState: AudioState
  left: OscillatorNode
  ctx: AudioContext
}

const useZzz = (): zzz => {
  const refs = useRef({
    ctx: null,
    gain: null,
    left: null,
    right: null,
    merger: null,
    noise: null,
    noiseG: null
  })
  // const compressor = useRef(ctxRef.current.createDynamicsCompressor())
  const [audioState, setAudioState] = useState<AudioState>('suspended')

  useEffect(() => {
    const ctx = new ((<any>window).AudioContext || (<any>window).webkitAudioContext)() // eslint-disable-line

    const gain = ctx.createGain()
    const left = ctx.createOscillator()
    const right = ctx.createOscillator()
    const merger = ctx.createChannelMerger()
    const noise = ctx.createBufferSource()
    const noiseG = ctx.createGain()

    noise.buffer = ctx.createBuffer(1, 2 * ctx.sampleRate, ctx.sampleRate)
    const channelArray = noise.buffer.getChannelData(0)
    for (let i = 0; i < 131072; i++) {
      channelArray[i] = (Math.random() * 2) - 1
    }
    noise.loop = true

    refs.current = {
      ctx,
      gain,
      left,
      right,
      merger,
      noise,
      noiseG
    }

    left.connect(merger, 0, 0)
    right.connect(merger, 0, 1)
    noise.connect(noiseG)

    merger.connect(gain)
    noiseG.connect(gain)
    gain.connect(ctx.destination)

    left.frequency.value = 220
    right.frequency.value = 200

    noiseG.gain.value = 0.03

    gain.gain.setValueAtTime(0, ctx.currentTime)
    // gain.gain.linearRampToValueAtTime(0.5, ctx.currentTime + 0.1)

    noise.start(0)
    left.start(0)
    right.start(0)

    setAudioState(ctx.state)
    return () => {
      gain.disconnect()
      merger.disconnect()
      right.disconnect()
      left.disconnect()
      refs.current = null
    }
  }, [])
  const setVol = (vol: number) => {
    if (vol >= 0 && vol < 1) {
      refs.current.gain.gain.setValueAtTime(refs.current.gain.gain.value, refs.current.ctx.currentTime)
      refs.current.gain.gain.linearRampToValueAtTime(vol, refs.current.ctx.currentTime + 0.53)
    }
  }

  const resume = useCallback(() => refs.current.ctx
    ? refs.current.ctx.resume()
      .then(() => {
        setAudioState(refs.current.ctx.state)
        return refs.current.ctx.state
      })
    : null
    , [])

  return {
    setVol,
    resume,
    audioState,
    left: refs.current.left,
    ctx: refs.current.ctx
  }
}

export default useZzz
